import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import * as React from 'react';
import PageLayout from '../components/common/page-layout/page-layout';
import Disclaimer from '../components/content/disclaimer/disclaimer';
import SectionIntended from '../components/templates/section-intended/section-intended';

const PrivacyPage = () => {
    const intl = useIntl();

    return (
        <PageLayout title={intl.formatMessage({ id: 'pages.disclaimer.title' })}>
            <SectionIntended>
                <h3 className="color-white" style={{ marginTop: '10px' }}>
                    <FormattedMessage id="pages.disclaimer.headline" />
                </h3>
                <Disclaimer />
            </SectionIntended>
        </PageLayout>
    );
};

export default PrivacyPage;
